import {figuredLocalStorage, globals, http, pusher, routes} from "Figured/Assets/Modules";
import {
    ACCOUNTING_SYNC_CONNECTION_STATUS_UPDATE,
    ACCOUNTING_SYNC_FAILED,
    XERO_SYNC_COMPLETED_JOB_NAME,
    XERO_SYNC_STARTED_JOB_NAME,
    XERO_SYNC_STATUS
} from "FarmCreation/Assets/consts";

// Gets the sync status from the browser localStorage
function getLocalSync() {
    return parseInt(figuredLocalStorage.getItem("sync-status-" + globals.getActiveFarmId()));
}

// Sets the sync status in the browser localStorage
function setLocalSync(status) {
    figuredLocalStorage.setItem("sync-status-" + globals.getActiveFarmId(), status);
}

export default {
    data() {
        return {
            isSyncing: false,
            status: XERO_SYNC_STATUS.WAITING,
            pusherPrivateFarmChannel: globals.get("pusherPrivateFarmChannel"),
        }
    },

    mounted() {
        this.checkLocalStatus();

        pusher.connect().then((pusher) => {
            let channel = pusher.subscribe(this.pusherPrivateFarmChannel);

            channel.bind("xero-sync", (data) => {
                switch (data.eventName) {
                case XERO_SYNC_STARTED_JOB_NAME:
                    this.setStatus(XERO_SYNC_STATUS.RUNNING);
                    break;
                case ACCOUNTING_SYNC_FAILED:
                    this.setStatus(XERO_SYNC_STATUS.ERROR);
                    break;
                case XERO_SYNC_COMPLETED_JOB_NAME:
                    this.setStatus(XERO_SYNC_STATUS.COMPLETE);
                    break;
                case ACCOUNTING_SYNC_CONNECTION_STATUS_UPDATE:
                    this.setStatus(data.connectionStatus ? XERO_SYNC_STATUS.COMPLETE : XERO_SYNC_STATUS.DISCONNECTED);
                    break;
                }
            });
        });
    },

    methods: {
        getLocalSync,
        setLocalSync,

        // Checks the localStorage status, updating the syncing status if it's running
        checkLocalStatus() {
            this.status    = this.getLocalSync();
        },

        startSync() {
            let route     = routes.get("pos_sync_start");

            http.post(route).then((response) => {
                this.setStatus(XERO_SYNC_STATUS.RUNNING);
            });
        },

        setStatus(status) {
            this.setLocalSync(status);
            this.status = status;
        }
    },

    watch: {
        status(newVal, oldVal)  {
            if (newVal == XERO_SYNC_STATUS.RUNNING) {
                this.isSyncing = true;
                this.$emit("syncEvent");
            } else {
                this.isSyncing = false;
            }
        },
    }
}
